<template>
  <b-card body-class="p-1">
    <div class="d-flex justify-content-between align-items-center">
      <b-avatar square :variant="keyState ?  'light-success' : 'light-danger'" class="mr-1">
        <feather-icon class="icon-state_img" :icon="keyState ? 'CheckIcon' : 'XIcon'"/>
      </b-avatar>
      <div class="mr-1">
        <div class="font-weight-bold">
          {{ typeKey }}
        </div>
        <div>
          {{ keyState ? 'Ключ успешно загружен' : 'Обновите ключ' }}
        </div>
      </div>
      <b-button
          v-b-modal.modal_edit_keys
          @click="updateKey"
          variant="flat-primary"
          class="btn-icon rounded-circle"
      >
        <feather-icon icon="RefreshCwIcon"/>
      </b-button>
    </div>
    <div class="mt-1">
      <div>
        <span style="font-size: 12px">прошло с последней проверки: </span>
        <span style="font-size: 12px" class="font-italic ml-2"> {{ ` ${getLastCheckTime}` }}</span>
      </div>
      <span style="cursor: pointer; font-size: 12px; color: #2c4762"
            @click="checkKey"
            class="font-weight-bold"
      >Проверить</span>
    </div>
  </b-card>
</template>

<script>
import {BCard, BAvatar, BButton} from "bootstrap-vue";
import {mapActions} from "vuex";

const dayjs = require('dayjs');
const relativeTime = require('dayjs/plugin/relativeTime');
const localeData = require('dayjs/locale/ru');


export default {
  name: "KeyApi",
  components: {
    BCard,
    BAvatar,
    BButton
  },
  data() {
    return {}
  },
  computed: {
    getLastCheckTime() {
      dayjs.extend(relativeTime);
      dayjs.locale(localeData);

      const lastCheckTime = dayjs(this.lastCheckTime);
      const currentTime = dayjs();

      return lastCheckTime.from(currentTime, true);
    }
  },

  props: {
    shortName: String,
    mpName: String,
    typeKey: String,
    lastCheckTime: String,
    keyState: {
      type: Boolean,
      default() {
        return true
      }
    }
  },
  inject: {},
  methods: {
    async checkKey() {
      await this.checkMainKey(this.shortName);
      await this.loadKeys()
    },
    ...mapActions('keys', ["loadKeys", "checkMainKey"]),
    updateKey() {
      this.$emit('updateKey', this.shortName)
    }
  }
}
</script>

<style scoped>
.icon-state {
  width: 42px;
  height: 42px;
  background-color: #E5F8ED;
}

.icon-state_img {
  width: 18px;
  height: 18px;
}
</style>