<template>
  <div class="setting-page-container d-flex align-items-start flex-wrap">
    <userCard/>
    <div class="key-container">
      <div class="key-header-button w-100 d-flex align-items-start justify-content-between">
        <div class="key-header mb-1 mx-1">
          <h3>
            API ключи
          </h3>
          <div style="min-width: 320px">
            Ключи можно получить на маркетплейсах в разделе настройки
          </div>
        </div>
      </div>
      <div class="main_wb_key mt-1 ml-1 align-items-start flex-wrap">
        <b-form-group
            :label="'WB ключ (общий): ' + ' ****' + partKeyWb + '. Годен до ' + geDateEndKey"
            label-for="textarea-formatter"
            class="mb-0"
        >
          <b-form-textarea
              id="textarea-formatter"
              v-model="keyValue"
              placeholder="Вставте в это поле ключ"
          />
          <div class="scoop_container d-flex flex-wrap">
            <div
                class="scoop_item d-flex justify-content-center align-items-center"
                :class="scoopsKey.includes(item) ? 'success_key' : 'undefined_key'"
                v-for="item in scoopsList">
              <span>{{ item }}</span>
            </div>
          </div>
          <b-button
              variant="primary"
              size="sm"
              @click="addOrUpdateKey"
              class="mr-1 d-flex align-items-center"
          >
            <b-spinner small v-if="staySendMainKey === 1"/>
            <feather-icon
                v-if="staySendMainKey === 2"
                icon="CheckIcon"
                class="mr-50"
            />
            <span class="ml-1">{{ getLabelState }}</span>
          </b-button>
        </b-form-group>
      </div>
      <div class="mt-1 ml-1 align-items-start flex-wrap">
        <b-form-group
            label="Cookies (Копировать как cURL)"
            label-for="textarea-formatter"
            class="mb-0"
        >
          <b-form-textarea
              id="textarea-formatter"
              v-model="cookiesValue"
              class="mb-1"
              placeholder="Вставить куки, из браузера"
          ></b-form-textarea>
          <b-button
              variant="primary"
              @click="checkAndSendCookieKey"
              size="sm"
              class="mr-1 d-flex align-items-center"
          >
            <b-spinner small v-if="staySendCookies === 1"/>
            <feather-icon
                v-if="staySendCookies === 2"
                icon="CheckIcon"
                class="mr-50"
            />
            <span class="ml-1">{{ getLabelState }}</span>
          </b-button>
        </b-form-group>
      </div>
      <div class="mt-1 ml-1 align-items-start flex-wrap">
        <b-form-group
            label="ID таблицы"
            label-for="textarea-formatter"
            class="mb-0"
        >
          <b-form-input
              id="textarea-formatter"
              v-model="tableIdValue"
              class="mb-1"
              placeholder="ID таблицы из URL google-таблиц"
          ></b-form-input>
          <b-button
              variant="primary"
              @click="checkAndSendTableId"
              size="sm"
              class="mr-1 d-flex align-items-center"
          >
            <b-spinner small v-if="staySendTableId === 1"/>
            <feather-icon
                v-if="staySendTableId === 2"
                icon="CheckIcon"
                class="mr-50"
            />
            <span class="ml-1">{{ getLabelStateTable }}</span>
          </b-button>
        </b-form-group>
      </div>
      <div class="mt-1 ml-1 align-items-start flex-wrap">
        <b-form-group
            label="Система налогообложения"
            label-for="textarea-formatter"
            class="mb-0"
        >
          <div class="d-flex justify-content-between ">
            <b-form-select
                id="textarea-formatter"
                :options="duesList"
                v-model="selectedDuesType"
                @change="setDuesValue"
                class="mb-1"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>-- Выберите СНО* --</b-form-select-option>
              </template>
            </b-form-select>
            <div class="d-flex justify-content-between ml-1">
              <b-form-input
                  id="textarea-formatter"
                  v-model="currentDues"
                  class="mb-1"
                  @input="setDuesValue"
                  placeholder="Укажите процент*"
              ></b-form-input>
              <span class="btn-icon ml-1">
            <b-spinner v-if="statusUpdDues === 'load'" small/>
            <feather-icon v-if="statusUpdDues === true" icon="CheckIcon"/>
            <feather-icon v-if="statusUpdDues === false" icon="XCircleIcon"/>
          </span>
            </div>
          </div>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {
  BButton,
  BFormSelect,
  BSpinner,
  BFormGroup,
  BFormInput,
  BFormSelectOption,
  BFormTextarea,
  BFormCheckbox
} from 'bootstrap-vue'
import KeyApi from "@/components/setting/KeyApi";
import userCard from "@/components/setting/userCard";

export default {
  name: "Setting",
  data() {
    return {
      keysVariant: {
        update: false,
        selectTypeKey: null
      },
      selectedDuesType: null,
      currentDues: null,
      keyValue: null,
      autoLoadNom: false,
      statusUpdDues: null,
      cookiesValue: '',
      tableIdValue: '',
      staySendCookies: 0,
      staySendTableId: 0,
      staySendMainKey: 0,
      borderColor: {}
    }
  },

  components: {
    BFormTextarea,
    BFormInput,
    BFormGroup,
    BSpinner,
    BFormSelectOption,
    userCard,
    BFormSelect,
    KeyApi,
    BFormCheckbox,
    BButton
  },

  methods: {
    ...mapActions('user', ['loadUserInfo', "loadUserStatuses", "getDues", "getDuesList", "writeDues"]),
    ...mapActions('keys', ["loadInfoKey", "loadScoops", "sendKey", "sendCookieKey", "sendTableId"]),
    async checkAndSendCookieKey() {
      this.staySendCookies = 1
      let res = await this.sendCookieKey(this.cookiesValue);
      if (res) {
        this.staySendCookies = 2
        setTimeout(() => this.staySendCookies = 0, 3000);
        return
      }
      this.staySendCookies = 0
    },

    async checkAndSendTableId() {
      this.staySendTableId = 1
      let res = await this.sendTableId(this.tableIdValue);
      if (res) {
        this.staySendTableId = 2
        setTimeout(() => this.staySendTableId = 0, 3000);
        return
      }
      this.staySendTableId = 0
    },

    async setDuesValue() {
      if (this.selectedDuesType && this.currentDues) {
        this.statusUpdDues = 'load'
        let res = await this.writeDues({duesType: this.selectedDuesType, dues: this.currentDues});
        if (res) this.statusUpdDues = true;
        else this.statusUpdDues = false;
        setTimeout(() => {
          this.statusUpdDues = null
        }, 3000)
      }
    },

    async addOrUpdateKey() {
      this.staySendMainKey = 1
      let res = await this.sendKey({
        key: this.keyValue
      })
      if (res) {
        this.staySendMainKey = 2
        setTimeout(() => this.staySendMainKey = 0, 3000);
        await this.loadInfoKey()
      }
      this.staySendMainKey = 0
    }
  },

  computed: {
    ...mapState('keys', ["scoopsList", "scoopsKey", "keyName", "dateEndKey", "partKeyWb"]),
    ...mapState('user', ["duesValue", "duesType", "duesList"]),
    getLabelState() {
      if (this.staySendCookies === 2) return 'Успешно'
      if (this.staySendCookies === 1) return 'Отправка...'
      return 'Отправить'
    },
    getLabelStateTable() {
      if (this.staySendTableId === 2) return 'Успешно'
      if (this.staySendTableId === 1) return 'Отправка...'
      return 'Отправить'
    },
    geDateEndKey() {
      return this.dateEndKey?.split('T')?.[0]
    }
  },

  async mounted() {
    await this.getDuesList()
    await this.loadInfoKey()
    await this.loadScoops()
    await this.getDues();
    this.selectedDuesType = this.duesType;
    this.currentDues = this.duesValue;
  },

  watch: {
    duesType(val) {
      this.selectedDuesType = val;
    },

    duesValue(val) {
      this.currentDues = val;
    }
  }

}
</script>

<style scoped>

.key-header {
}

.key-container {
  width: calc(100% - 280px - 1rem);
  min-width: 320px;
}

.scoop_container {
  margin-top: 10px;
}

.scoop_item {
  font-size: 10px;
  border: 1px solid;
  border-radius: 100px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.scoop_item span {
  margin: 2px 4px;
}


.success_key {
  border-color: green;
  color: green;
}


.bad_key {
  border-color: red;
  color: red;
}


.undefined_key {
  border-color: gray;
  color: gray;
}

@media (max-width: 870px) {
  .key-header-button {
    flex-wrap: wrap !important;
  }

  .key-header-button button {
    margin: 1rem 0;
  }
}

</style>