<template>
  <div>
    <b-card class="user-card mr-1">
      <div class="d-flex align-items-center justify-content-center position-relative"
           style="min-height: 25px"
      >
        <img src="https://app.upakovan.online/media/avatar/user/ava.jpeg" class="img-ava"/>
        <b-button
            @click="delegateClick"
            size="sm"
            class="update-avatar-btn btn-icon rounded-circle position-absolute"
            variant="flat-primary"
        >
          <feather-icon icon="RefreshCcwIcon"/>
        </b-button>
      </div>
      <div class="name-user my-1 pb-1 text-center">
        <span>{{ userInfo.legalStatus }}</span> {{ userInfo.name }}
      </div>
      <b-card-text class="">
        <div class="font-weight-bolder">Email: <span class="font-weight-normal"> {{ userInfo.email }} </span></div>
        <div style="margin: 10px 0" class="font-weight-bolder">ИНН: <span
            class="font-weight-normal"> {{ userInfo.inn }} </span></div>
        <div class="font-weight-bolder">Телефон: <span class="font-weight-normal"> {{ userInfo.tel }} </span></div>
        <div style="margin: 10px 0">
          <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.447773 10.3743C0.447281 12.1387 0.911906 13.8615 1.79538 15.38L0.363281 20.5683L5.71434 19.1761C7.19438 19.9756 8.85265 20.3945 10.5378 20.3946H10.5422C16.1052 20.3946 20.6335 15.903 20.6359 10.3822C20.637 7.70693 19.588 5.19133 17.682 3.29872C15.7765 1.40628 13.2421 0.363526 10.5418 0.362305C4.9782 0.362305 0.450152 4.8537 0.447855 10.3743"
                fill="url(#paint0_linear_379_60585)"/>
            <path
                d="M0.0877734 10.3711C0.0871992 12.199 0.568476 13.9834 1.48345 15.5563L0 20.9306L5.54293 19.4885C7.07019 20.3147 8.78973 20.7504 10.5395 20.751H10.544C16.3065 20.751 20.9975 16.0978 21 10.3794C21.001 7.60802 19.9142 5.00199 17.9402 3.04158C15.966 1.08142 13.341 0.00113954 10.544 0C4.78045 0 0.0900703 4.65256 0.0877734 10.3711ZM3.38871 15.2854L3.18175 14.9594C2.31172 13.5868 1.85251 12.0005 1.85317 10.3717C1.85505 5.61864 5.75351 1.75163 10.5473 1.75163C12.8687 1.7526 15.0504 2.65056 16.6914 4.27977C18.3323 5.90914 19.2352 8.07507 19.2346 10.3787C19.2325 15.1318 15.3339 18.9993 10.544 18.9993H10.5405C8.98086 18.9985 7.45123 18.5829 6.11723 17.7975L5.79977 17.6107L2.51048 18.4664L3.38871 15.2853V15.2854Z"
                fill="url(#paint1_linear_379_60585)"/>
            <path
                d="M7.93012 6.03501C7.73439 5.60337 7.52841 5.59466 7.34228 5.58709C7.18987 5.58057 7.01563 5.58106 6.84156 5.58106C6.66733 5.58106 6.38424 5.6461 6.14495 5.90534C5.90542 6.16483 5.23047 6.7919 5.23047 8.06728C5.23047 9.34275 6.16669 10.5753 6.2972 10.7484C6.42788 10.9212 8.1046 13.6223 10.7601 14.6614C12.9671 15.5249 13.4162 15.3531 13.8952 15.3098C14.3742 15.2667 15.441 14.6829 15.6586 14.0776C15.8764 13.4723 15.8764 12.9535 15.8111 12.8451C15.7458 12.7371 15.5716 12.6722 15.3103 12.5426C15.049 12.413 13.7645 11.7858 13.5251 11.6993C13.2855 11.6129 13.1114 11.5697 12.9371 11.8293C12.7629 12.0885 12.2626 12.6722 12.1101 12.8451C11.9578 13.0184 11.8053 13.04 11.5441 12.9103C11.2827 12.7802 10.4413 12.5068 9.44302 11.6238C8.66635 10.9366 8.142 10.0881 7.98959 9.82852C7.83718 9.56935 7.97327 9.42887 8.10427 9.29969C8.22166 9.18354 8.36562 8.99698 8.49638 8.84567C8.62664 8.69427 8.67012 8.58626 8.75724 8.41338C8.84444 8.24033 8.8008 8.08894 8.73558 7.95927C8.67012 7.82961 8.16243 6.54755 7.93012 6.03501Z"
                fill="white"/>
            <defs>
              <linearGradient id="paint0_linear_379_60585" x1="1013.99" y1="2020.96" x2="1013.99" y2="0.362305"
                              gradientUnits="userSpaceOnUse">
                <stop stop-color="#1FAF38"/>
                <stop offset="1" stop-color="#60D669"/>
              </linearGradient>
              <linearGradient id="paint1_linear_379_60585" x1="1050" y1="2093.06" x2="1050" y2="0"
                              gradientUnits="userSpaceOnUse">
                <stop stop-color="#F9F9F9"/>
                <stop offset="1" stop-color="white"/>
              </linearGradient>
            </defs>
          </svg>
          <span>  {{ userInfo.whatsapp }} </span>
        </div>
        <div>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9 -0.000976562C6.61359 -0.000976562 4.32281 0.94782 2.63672 2.63504C0.948885 4.32293 0.000467536 6.61203 0 8.99902C0 11.385 0.949219 13.6758 2.63672 15.363C4.32281 17.0502 6.61359 17.999 9 17.999C11.3864 17.999 13.6772 17.0502 15.3633 15.363C17.0508 13.6758 18 11.385 18 8.99902C18 6.61304 17.0508 4.32226 15.3633 2.63504C13.6772 0.94782 11.3864 -0.000976562 9 -0.000976562Z"
                fill="url(#paint0_linear_379_60572)"/>
            <path
                d="M4.07413 8.90405C6.69819 7.76105 8.44756 7.00744 9.32225 6.64337C11.8226 5.60373 12.3415 5.42316 12.6804 5.41705C12.7549 5.41585 12.9208 5.43427 13.0291 5.52181C13.1191 5.59564 13.1444 5.69548 13.1571 5.76558C13.1683 5.83562 13.1838 5.99523 13.1712 6.11982C13.0362 7.54294 12.4498 10.9964 12.1516 12.5904C12.0265 13.2648 11.7776 13.491 11.5371 13.513C11.014 13.5611 10.6174 13.1677 10.1112 12.8359C9.31944 12.3166 8.87225 11.9934 8.10303 11.4868C7.21428 10.9012 7.79085 10.5793 8.2971 10.0534C8.42928 9.91571 10.7327 7.8211 10.7763 7.63112C10.7819 7.60735 10.7876 7.51876 10.7341 7.47207C10.6821 7.42524 10.6048 7.44127 10.5485 7.45393C10.4683 7.47193 9.20413 8.30837 6.75163 9.9631C6.39303 10.2098 6.06819 10.33 5.77569 10.3237C5.45506 10.3168 4.83631 10.142 4.37647 9.99263C3.81397 9.8094 3.36538 9.71251 3.40475 9.4013C3.42444 9.2393 3.64803 9.07351 4.07413 8.90405Z"
                fill="white"/>
            <defs>
              <linearGradient id="paint0_linear_379_60572" x1="900" y1="-0.000976562" x2="900" y2="1800"
                              gradientUnits="userSpaceOnUse">
                <stop stop-color="#2AABEE"/>
                <stop offset="1" stop-color="#229ED9"/>
              </linearGradient>
            </defs>
          </svg>
          <span>  {{ userInfo.telegram }} </span>
        </div>
      </b-card-text>
      <b-button class="mt-1"
                v-b-modal.modal_edit_user
                variant="outline-primary">Править
      </b-button>
      <b-form-file class="invisible hidden-button-file position-absolute"
                   @input="onSubmit"
      ></b-form-file>
    </b-card>
    <b-modal
        id="modal_edit_user"
        body-class="py-2 px-3"
        footer-class="d-flex align-items-center justify-content-center border-0"
        hide-header
        centered
        cancel-title="отмена"
        ok-title="Сохранить"
        @ok="sendNewUserData"
    >
      <div class="user_edit-container text-center">
        <h2 class="user_edit-header">Заполните информацию о себе</h2>
        <div>Поля со звездочкой обязательны для заполнения</div>
        <div class="mt-1 text-left user_edit-fields_container d-flex justify-content-center align-items-center ">
          <div class="user_edit-input_block mr-2">
            <div class="status">
              <label for="input-live">Правовой статус*:</label>
              <b-form-select v-model="currentLegalStatus"
                             :options="listLegalStatus.map(status=>status.name)"></b-form-select>
            </div>
            <div class="INN my-1">
              <label for="input-live">ИНН*:</label>
              <b-form-input
                  type="number"
                  v-model="currentINN"
                  placeholder="123456789"
                  trim
              ></b-form-input>
            </div>
            <div class="telegram">
              <label for="input-live">Телеграмм:</label>
              <b-form-input
                  type="number"
                  v-model="currentTelegram"
                  placeholder="@nickname"
                  trim
              ></b-form-input>
            </div>
          </div>
          <div class="user_edit-input_block">
            <div class="name">
              <label for="input-live">{{ currentLegalStatus === "ooo" ? 'Имя компании*' : 'ФИО*' }}</label>
              <b-form-input
                  id="tel"
                  name="name"
                  v-model="currentName"
                  :placeholder="currentLegalStatus === 'ooo' ? 'Моя компания*' : 'Иванов Александр Сергеевич*'"
                  trim
              ></b-form-input>
            </div>
            <div class="tel  my-1">
              <label for="input-live">Телефон*:</label>
              <b-form-input
                  id="tel"
                  name="tel"
                  type="number"
                  v-model="currentTel"
                  placeholder="+7 (000) 000-00-00"
                  trim
              ></b-form-input>
            </div>
            <div class="whats-app">
              <label for="input-live">Вотсап:</label>
              <b-form-input
                  type="number"
                  v-model="currentWhatsApp"
                  placeholder="+7 (000) 000-00-00"
                  trim
              ></b-form-input>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import axios from 'axios'
import {
  BCard,
  BAvatar,
  BButton,
  BCardText,
  BImg,
  BFormFile,
  BModal,
  BFormSelect,
  BFormInput,
  BFormTextarea,
  VBModal
} from "bootstrap-vue";
import login from "@/views/Login";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import user from "@/store/user";

export default {
  name: "userCard",
  data() {
    return {
      image: null,
      currentLegalStatus: null,
      keyForMP: 'wb',
      currentTelegram: null,
      currentTel: null,
      currentINN: null,
      currentName: null,
      currentWhatsApp: null
    }
  },
  directives: {
    'b-modal': VBModal
  },

  computed: {
    ...mapState('user', ['userInfo']),
    ...mapState('user', ['listLegalStatus']),
    ...mapState('user', ['mpKeys']),
  },

  components: {
    BCard,
    BModal,
    BAvatar,
    BButton,
    BCardText,
    BFormTextarea,
    BImg,
    BFormFile,
    BFormSelect,
    BFormInput,
  },
  methods: {
    ... mapActions('user', ['loadUserInfo']),
    async sendNewUserData() {
      let data = {};
      if (this.currentLegalStatus) data.status = this.currentLegalStatus;
      if (this.currentTelegram) data.telegram = this.currentTelegram;
      if (this.currentTel) data.tel = this.currentTel;
      if (this.currentINN) data.inn = this.currentINN;
      if (this.currentName) data.name = this.currentName;
      if (this.currentWhatsApp) data.whatsapp = this.currentWhatsApp;
      let res = await axios.post('/ff/update_user_info', data);
      if (res.status === 200) {
        await this.loadUserInfo();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Данные успешная обновлены`,
            icon: 'CheckSquareIcon',
            variant: 'success',
          },
        });
      }
    },
    delegateClick() {
      const input = document.querySelector('.hidden-button-file input')
      input.click()
    },
    onSubmit(event) {
      console.log(event);
      const formData = new FormData()
      formData.append('ava', event)
      axios.post("/ff/add_avatar", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
          .then(response => {
            let imgAva = document.querySelector('.img-ava');
            imgAva.src = 'https://app.upakovan.online/media/avatar/user/ava.jpeg';
          })
          .catch(err => {
            console.log(err);
            let imgAva = document.querySelector('.img-ava');
            imgAva.src = 'https://app.upakovan.online/media/avatar/user/ava.jpeg';

          })
    }
  },
}
</script>

<style scoped>

.user-card {
  width: 280px;
}

.img-ava {
  max-width: 120px;
  max-height: 120px;
}

.update-avatar-btn {
  bottom: -5px;
  right: 5px;
}

.hidden-button-file {
  max-width: 0;
  overflow: hidden;
}

.user_edit-input_block {
  width: 220px;
}

.name-user {
  font-size: 18px;
  font-weight: 500;
  border-bottom: 1px solid #EBE9F1;
}

.user_edit-header {
  font-weight: 500;
  font-size: 26px;
}

@media (max-width: 500px) {
  .user_edit-input_block:last-child {
    margin-top: 1rem;
  }

  .user_edit-fields_container {
    justify-content: start !important;
    flex-wrap: wrap;
  }
}

</style>